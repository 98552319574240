import React, { RefCallback, useState } from 'react'
import {
  Button,
  BoxProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Card,
  CardHeader,
  CardHeading,
  CardBody
} from '~/design_system'
import { api } from '~/api/sign_in'
import { cleanLocalState as signInCleanLocalState } from './Password'
import { autoFocusRef } from '~/common/domUtils'
import { useMsg } from '~/common/localizationUtils'

const cleanLocalState = () => {
  signInCleanLocalState()
}

export const SignIn = (props: BoxProps) => {
  const [username, setUsername] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const msg = useMsg()
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('')
    setUsername(event.target.value)
  }
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    api
      .validateUsername({ username, queryString: window.location.search })
      .then(response => {
        if (response.next) {
          window.location.href = response.next
        }
      })
      .catch(response => {
        setErrorMessage(response?.body?.error_message)
      })
  }

  cleanLocalState()

  return (
    <Card {...props}>
      <CardHeader>
        <CardHeading>{msg('signin.sign_in')}</CardHeading>
      </CardHeader>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <FormControl id="username" mb={6} isInvalid={!!errorMessage}>
            <FormLabel>{msg('signin.username')}</FormLabel>
            <Input
              onChange={handleChange}
              data-testid="sign-in-page-username-input"
              ref={autoFocusRef() as RefCallback<HTMLInputElement>}
            />
            <FormErrorMessage>{errorMessage}</FormErrorMessage>
          </FormControl>
          <Button type="submit" data-testid="sign-in-page-continue-btn" variant="primary">
            {msg('signin.continue')}
          </Button>
        </form>
      </CardBody>
    </Card>
  )
}
