import { Box, Link, Stack, Img } from '~/design_system'
import { useMsg } from '~/common/localizationUtils'

/*
It is expected that the Footer component is inside the design system's Container component this will avoid issues
with the Contact Us sidebar button
 */
export const Footer = props => {
  const msg = useMsg()

  /* TODO: Update existing localized string values to new Workday strings listed below */
  return (
    <Box mt={12} mb={10}>
      <Stack as="footer" spacing={{ base: 4, md: 1 }} alignItems="center" textStyle="bodySmall" {...props}>
        <Img pb={{ base: 0, md: 2 }} w="84px" src="/static/images/workday/logo_wday.svg" alt="Workday Logo" />
        <Box textAlign="center">
          <Link variant="link" href="https://www.workday.com/en-us/privacy.html">
            Privacy
          </Link>
          <Box as="span" ml={1}>
            © {new Date().getFullYear()} Workday, Inc. All rights reserved. Proprietary and Confidential - For
            Authorized Use Only
          </Box>
        </Box>
        <Link variant="link" href="/home/tnc">
          Workday VNDLY - {msg('footer.terms_of_use')}
        </Link>
      </Stack>
    </Box>
  )
}
