import React from 'react'
import { BoxProps, TextBlockTwoLineMuted, Box } from '~/design_system'

import { useSignInPageHelpTextQuery } from '~/api/sign_in'

export const SignInHelpText = (props: BoxProps) => {
  const { data: signInPageHelpText } = useSignInPageHelpTextQuery()

  return (
    <>
      {signInPageHelpText?.sign_in_help_text_enabled && (
        <Box {...props}>
          <TextBlockTwoLineMuted
            primaryText="Notice:"
            secondaryText={
              <Box
                sx={{
                  a: {
                    color: 'var(--vndly-color-link-inline-dark)',
                    fontWeight: 'semibold',
                    textDecoration: 'underline',
                    _hover: {
                      color: 'var(--vndly-color-link-inline-dark-hover)'
                    }
                  }
                }}
                dangerouslySetInnerHTML={{ __html: signInPageHelpText.sign_in_help_text }}
              />
            }
          />
        </Box>
      )}
    </>
  )
}
