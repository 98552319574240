import { useRouter } from 'next/router'
import React from 'react'
import { useIsAuthenticatedQuery } from '~/api/accounts'
import { preloadImage } from '~/common/domUtils'
import { TOP_NAV_HEIGHT_PX } from '~/common/scrollUtils'
import { Box, Container, Flex, Image } from '~/design_system'
import { Footer } from '~/home/Footer'
import { BaseLayout } from './BaseLayout'

preloadImage('/static/images/logo-workday-vndly.svg')

export const SignInLayout = ({ children }) => {
  const imageHeight = '40px'

  return (
    <>
      <BaseLayout />
      <Flex
        as="header"
        alignItems="center"
        bgColor="var(--vndly-color-navigation-menu-bar-background)"
        boxShadow="0px 2px 4px rgba(0, 0, 0, 0.12)"
        h={TOP_NAV_HEIGHT_PX}
        px={4}>
        <Image
          src="/static/images/logo-workday-vndly.svg"
          alt="Workday Vndly logo"
          id="workday-vndly-logo"
          width="208px"
          height={imageHeight}
        />
      </Flex>
      <Box pos="absolute" w="full" h={200} zIndex="-1" bgColor="blueberry400" />
      <Container display="flex" alignItems="center" flexDir="column" minH={`calc(100vh - ${imageHeight})`}>
        <Box w={['100%', '500px']} flex="1">
          {children}
        </Box>
        <Footer />
      </Container>
    </>
  )
}

export function useRedirectToHomeIfAuthenticated() {
  const router = useRouter()
  useIsAuthenticatedQuery({
    onSuccess({ is_authenticated }) {
      // The url /home/login/ works for all user types, /home/all breaks for contractors
      if (is_authenticated) router.replace('/home/login/')
    }
  })
}
