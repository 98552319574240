import Head from 'next/head'
import { SignInLayout, useRedirectToHomeIfAuthenticated } from '~/components/v2/layouts/SignInLayout'
import { SignIn } from '~/sign_in/SignIn'
import { SignInHelpText } from '~/sign_in/SignInHelpText'
import { ContactUsDrawer } from '~/components/v2/ContactUsDrawer'

const SignInPage = () => {
  useRedirectToHomeIfAuthenticated()
  return (
    <>
      <Head>
        <title>Sign In - Workday VNDLY</title>
      </Head>
      <SignIn mt={12} />
      <SignInHelpText my={6} />
      <ContactUsDrawer />
    </>
  )
}

SignInPage.trackPageViews = false
SignInPage.anonymous = true
SignInPage.layout = SignInLayout

export default SignInPage
