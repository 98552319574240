import React, { RefCallback, useState } from 'react'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputPassword,
  Box,
  Button,
  BoxProps,
  Link,
  Stack,
  useDisclosure,
  Card,
  CardHeader,
  CardHeading,
  CardBody,
  Text
} from '~/design_system'
import { api } from '~/api/sign_in'
import { ArrowLeft } from '~/icons'
import { ForgotPasswordModal } from './ForgotPasswordModal'
import { autoFocusRef } from '~/common/domUtils'
import { useMsg } from '~/common/localizationUtils'

export const cleanLocalState = () => {
  if (typeof window !== 'undefined') {
    // We have removed jwt_token from the app, but if any lingering tokens are out there
    // logout should still remove them. And we have saved it in both localStorage and sessionStorage
    // so clear from both
    window.localStorage.removeItem('jwt_token')
    window.sessionStorage.removeItem('jwt_token')
    // Sometimes we called jwt_token token for some dumb reason
    window.localStorage.removeItem('token')
    window.sessionStorage.removeItem('token')
  }
}

export const Password = (props: BoxProps) => {
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const msg = useMsg()
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('')
    setPassword(event.target.value)
  }
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    api
      .validatePassword({ password, queryString: window.location.search })
      .then(response => {
        if (response.next) {
          window.location.href = response.next
        }
      })
      .catch(response => {
        setErrorMessage(response?.body?.error_message)
      })
  }

  return (
    <>
      <ForgotPasswordModal isOpen={isOpen} onClose={onClose} />
      <Card {...props}>
        <CardHeader>
          <Box flex="1">
            <Link href="/sign_in" variant="inlineGray">
              <Text as="span">
                <ArrowLeft mr="2" />
                {msg('signin.back')}
              </Text>
            </Link>
          </Box>
          <CardHeading flex="1" textAlign="center">
            {msg('signin.password')}
          </CardHeading>
          <Box flex="1" />
        </CardHeader>
        <CardBody>
          <form onSubmit={handleSubmit}>
            <FormControl id="password" mb="9" isInvalid={!!errorMessage}>
              <Stack direction="row" justifyContent="space-between">
                <FormLabel>{msg('signin.enter_password')}</FormLabel>
                <Button variant="inlineLink" mb="2" onClick={onOpen}>
                  {msg('signin.forgot_password')}
                </Button>
              </Stack>
              <InputPassword
                onChange={handleChange}
                data-testid="password-page-password-input"
                ref={autoFocusRef() as RefCallback<HTMLInputElement>}
              />
              <FormErrorMessage>{errorMessage}</FormErrorMessage>
            </FormControl>
            <Button type="submit" data-testid="password-page-sign-in-btn" variant="primary">
              {msg('signin.sign_in')}
            </Button>
          </form>
        </CardBody>
      </Card>
    </>
  )
}
