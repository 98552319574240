import React, { useState } from 'react'
import { useRouter } from 'next/router'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Text,
  useToast
} from '~/design_system'
import { api } from '~/api/sign_in'
import { useMsg } from '~/common/localizationUtils'

interface ForgotPasswordModalProps {
  isOpen: boolean
  onClose(): void
}

export const ForgotPasswordModal = ({ isOpen, onClose }: ForgotPasswordModalProps) => {
  const router = useRouter()
  const toast = useToast()
  const [username, setUsername] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const initialRef = React.useRef() as React.MutableRefObject<HTMLInputElement>
  const msg = useMsg()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('')
    setUsername(event.target.value)
  }
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    api
      .forgotPassword({ username })
      .then(() => {
        toast({
          description: msg('signin.existing_account_matches_username', { username }),
          status: 'success',
          position: 'top',
          isClosable: true
        })
        router.push('/sign_in')
      })
      .catch(() => {
        setErrorMessage(msg('signup.error_message'))
      })
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" initialFocusRef={initialRef}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{msg('signin.forgot_password')}</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <Text mb="5">{msg('signin.enter_username_associated_with_account')}</Text>
            <FormControl id="username" isInvalid={!!errorMessage}>
              <FormLabel>{msg('signin.enter_username')}</FormLabel>
              <Input onChange={handleChange} data-testid="forgot-password-modal-username-input" ref={initialRef} />
              <FormErrorMessage>{errorMessage}</FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button type="submit" data-testid="forgot-password-modal-send-email-btn" mr="4" variant="primary">
              {msg('signin.email_me_reset_instructions')}
            </Button>
            <Button variant="secondary" onClick={onClose} data-testid="forgot-password-modal-cancel-btn">
              {msg('signin.cancel')}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
