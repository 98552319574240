import { useQuery } from '@tanstack/react-query'
import fetch from '../common/fetch'

interface UsernameRequest {
  username: string
  queryString: string
}

interface PasswordRequest {
  password: string
  queryString: string
}

interface ForgotPasswordRequest {
  username: string
}

interface ResetPasswordRequest {
  password: string
  confirm_password: string
  key: string
}

export const api = {
  validateUsername: ({ username, queryString }: UsernameRequest) =>
    fetch.post(`/accounts/login/identity/${queryString}`, { username }),
  validatePassword: ({ password, queryString }: PasswordRequest) =>
    fetch.post(`/accounts/login/pwd/${queryString}`, { password }),
  forgotPassword: ({ username }: ForgotPasswordRequest) => fetch.post(`/accounts/forgot_password/`, { username }),
  getSignInPageHelpText: (): Promise<SignInHelpTextResponse> => fetch.get('/api/sign-in-page-help-text'),
  getResetPasswordPageInitialData: (): Promise<PasswordResetPageInitialDataResponse> =>
    fetch.get('/api/v2/accounts/reset_password_page_initial_data'),
  resetPassword: ({ password, confirm_password, key }: ResetPasswordRequest) =>
    fetch.post('/accounts/update_new_password', { password, confirm_password, key })
}

interface SignInHelpTextResponse {
  sign_in_help_text_enabled: boolean
  sign_in_help_text: string
}

export interface PasswordResetPageInitialDataResponse {
  minimum_password_length: number
}

export const useSignInPageHelpTextQuery = () => {
  return useQuery({ queryKey: ['sign_in', 'help_text'], queryFn: () => api.getSignInPageHelpText() })
}
export const useResetPasswordPageInitialData = () => {
  return useQuery({ queryKey: ['sign_in', 'password_reset'], queryFn: () => api.getResetPasswordPageInitialData() })
}
